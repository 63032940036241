@import '../../styles.scss';

.card-static {
  width: $view-width;
  height: $card-height;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: $dark-grey;
  overflow: hidden;
  padding-bottom: 20px;
  margin: 20px auto 0 auto;
  background-color: $white;
  box-shadow: $large-box-shadow;
  border-bottom-left-radius: $large-border-radius;
  border-bottom-right-radius: $large-border-radius;
  overflow: hidden;

  .card-header {
    width: 100%;
    min-height: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
    background-color: $dark-grey;
  }
}

.card {
  width: $view-width;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: $dark-grey;
  overflow: hidden;
  margin: 20px auto 0 auto;
  background-color: $white;
  box-shadow: $large-box-shadow;
  border-bottom-left-radius: $large-border-radius;
  border-bottom-right-radius: $large-border-radius;

  .card-header {
    width: 100%;
    min-height: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    margin-bottom: 40px;
    background-color: $dark-grey;
  }
}

@media (max-width: 1000px) {
  .card,
  .card-static {
    width: $view-width-small;
  }
}

@media (max-width: 800px) {
  .card,
  .card-static {
    width: 85vw;
  }
}
