@import '../../../../styles.scss';

.tab-view {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .inner-tab-content {
    height: 525px;
    overflow-y: auto;
    width: $inner-view-width;
    padding-bottom: 20px;
  }
}

@media (max-width: 1400px) {
  * {
    transition: $transition;
  }

  .tab-view {
    h1 {
      font-size: 24px !important;
    }

    p {
      font-size: 14px !important;
    }
  }
}

@media (max-width: 800px) {
  * {
    transition: $transition;
  }

  .tab-view {
    .inner-tab-content {
      width: 325px !important;
    }
  }
}
