@import '../../../../../styles.scss';

.property-modal {
  width: 90%;
  z-index: 3000;

  .property-description {
    max-height: 250px;
    overflow-y: auto;
  }

  .tag-button-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .modal-value-table {
    width: 100%;
    min-height: 50px;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .modal-value-table-row {
      display: flex;
      align-items: center;
      border: solid 1px transparent;
      border-radius: $small-border-radius;
      padding: 10px 0;
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 1400px) {
  .property-modal {
    .modal-value-table {
      .modal-value-table-row {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
      }
    }
  }
}
