@import '../../../../styles.scss';

.tab-view {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  height: 70vh !important;

  .inner-tab-content {
    height: 87.5vh !important;
    width: $inner-view-width;
  }
}

@media (max-width: 1400px) {
  * {
    transition: $transition;
  }

  .tab-view {
    h1 {
      font-size: 24px !important;
    }

    p {
      font-size: 14px !important;
    }
  }
}
