@import 'styles.scss';

.tabs-container {
  max-width: 750px;
  height: 25px;
  display: flex;
  align-items: center;
  flex-direction: column;

  .tabs-buttons {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .tab-button,
  .tab-button-active {
    width: 100px;
    line-height: 1.5;
    font-size: 14px;
    text-align: center;
    margin: 0 auto;
    border-bottom: solid 3px transparent;

    &:hover {
      cursor: pointer;
    }
  }

  .tab-button-active {
    border-bottom: solid 3px $green;
  }

  .tabs-content {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 800px) {
  .tabs-container {
    .tab-button,
    .tab-button-active {
      width: 70px;
      font-size: 12px;
    }
  }
}
