@import '../../styles.scss';

.implementation-container {
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  margin: 10px auto;

  .css-markup {
    width: 100%;
    min-height: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 18px;
    padding: 8px 0;
    font-family: monospace;
    background-color: $dark-grey;
    outline: solid 5px transparent;
    border-radius: $small-border-radius;
    margin: 0;
    overflow-x: auto;

    .line {
      width: clamp(100px, 400px, 90%);
      margin-left: 25%;
    }

    .selector {
      color: $code-selector;
    }

    .property {
      color: $code-attribute;

      &:first-child {
        margin-left: 20px;
      }
    }

    .property-value {
      color: $code-attribute-value;
    }
  }

  .tag-copy {
    height: 100%;
    padding: 8px 0;
    margin: auto;
  }
}
