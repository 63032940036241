@import '../../styles.scss';

.header {
  top: 0;
  left: 0;
  width: 100vw;
  height: 60px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: $main-font;
  background-color: $dark-grey;
  padding: 0 10px;

  .header-logo {
    width: 250px;
    margin: 10px;
  }

  .menu {
    width: 250px;
    display: flex;

    .nav-link,
    .nav-link-active {
      height: 55px;
      display: grid;
      place-items: center;
      color: $white;
      text-emphasis: none;
      text-decoration: none;
      letter-spacing: 1px;
      border-bottom: solid 5px transparent;
      margin: 0 auto;

      &:hover {
        color: $green;
        cursor: pointer;
      }
    }

    .nav-link-active {
      border-bottom: solid 5px $green;
    }
  }
}

@media (max-width: 800px) {
  * {
    transition: $transition;
  }

  .menu {
    width: 200px;

    .nav-link,
    .nav-link-active {
      font-size: 12px;
    }
  }
}
