@import '../../styles.scss';

.list-table {
  height: 950px;
  width: $inner-tab-width-small;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  overflow-x: hidden;

  .clear-search-icon {
    &:hover {
      cursor: pointer;
    }
  }

  .tooltip {
    .list-table-row {
      width: 95%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto 5px 0;
      text-emphasis: none;
      text-decoration: none;
      border: solid 3px $light-grey;
      border-radius: $small-border-radius;

      .list-table-row-title,
      .list-table-row-value {
        padding: 0 10px;
        font-size: 18px;
        color: $dark-grey;
      }

      .list-table-row-value {
        font-size: 16px;
      }

      &:hover {
        cursor: pointer;
        border: solid 3px $green;
        box-shadow: $small-box-shadow;

        .list-table-row-title,
        .list-table-row-value {
          color: $green;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .list-table {
    width: $inner-tab-width-small;

    .tooltip {
      .list-table-row {
        .list-table-row-title,
        .list-table-row-value {
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .list-table {
    width: $inner-tab-width-smaller;

    .tooltip {
      .list-table-row {
        height: 40px;

        .list-table-row-title,
        .list-table-row-value {
          font-size: 12px;
        }
      }
    }
  }
}
