@import '../../styles.scss';

.tooltip {
  width: 100%;
  position: relative;
  display: inline-block;
}

.tooltip-content {
  z-index: 1000;
  top: 100%;
  left: 50%;
  position: absolute;
  visibility: hidden;
  margin: 0;
  padding: 8px;
  font-size: 14px;
  color: $dark-grey;
  text-align: center;
  border-radius: 4px;
  white-space: nowrap;
  letter-spacing: 0.5px;
  background-color: $green;
  transition: visibility 0s, opacity 0.3s linear;
  transform: translate3d(-50%, -10px, 0);
}

.tooltip:hover .tooltip-content {
  visibility: visible;
  margin: 0;
}

@media (max-width: 800px) {
  .tooltip-content {
    display: none;
  }
}
