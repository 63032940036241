@import '../../../../../styles.scss';

.infobook-icon-container {
  width: 600px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto 40px auto;
  transform: translateY(-20px);

  .icon-column {
    height: 100%;
    display: flex;
    flex-direction: column;

    .infobook-item,
    .infobook-item-disabled {
      width: 250px;
      height: 60px;
      display: flex;
      align-items: center;
      border: solid 3px $white;
      border-radius: $small-border-radius;
      margin-bottom: 20px;
      &:hover {
        cursor: pointer;
        border: solid 3px $green;
        transition: $transition;
      }
    }

    .infobook-item-disabled {
      opacity: 0.1;

      &:hover {
        cursor: default;
        border: solid 3px $white;
      }
    }
  }
}

@media (max-width: 800px) {
  .infobook-icon-container {
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .icon-column {
      .infobook-item,
      .infobook-item-disabled {
        width: 100%;
        height: 40px;
        margin-bottom: 10px;
      }
    }
  }
}
