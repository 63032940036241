@import '../../styles.scss';

.implementation-container {
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .markup {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 16px;
    padding: 8px 0;
    font-family: monospace;
    background-color: $dark-grey;
    outline: solid 5px transparent;
    border-radius: $small-border-radius;

    .line {
      margin: 0 auto;
    }

    .tag-name {
      color: $code-tag;
    }

    .tag-text {
      color: $code-text;
      margin: 0 10px;
    }
  }

  .markup-with-attributes {
    width: 100%;
    min-height: 20px;
    max-height: 300px;
    overflow-y: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 16px;
    padding: 8px 0;
    font-family: monospace;
    background-color: $dark-grey;
    outline: solid 5px transparent;
    border-radius: $small-border-radius;

    .line {
      width: clamp(100px, 375px, 600px);
      margin-left: 25%;
    }

    .tag-name {
      color: $code-tag;
    }

    .tag-attribute {
      color: $code-attribute;

      &:first-child {
        margin-left: 20px;
      }
    }

    .tag-attribute-value {
      color: $code-attribute-value;
    }

    .tag-text {
      margin-left: 20px;
      color: $code-text;
    }
  }

  .tag-copy {
    height: 100%;
    padding: 8px 0;
    margin: auto;
  }

  &:hover {
    cursor: pointer;
  }
}
