@import '../../../../../styles.scss';

.code-example-container {
  max-width: 630px;
  margin: 0 auto;

  .code-example-copy {
    width: 624px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 3px $green;

    &:hover {
      background-color: $green;
      cursor: pointer;
    }
  }
}
