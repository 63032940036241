@import '../../styles.scss';

.search {
  width: calc($inner-tab-width-small + 50px);
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  padding: 0 20px;
  transform: translateY(-20px);
  background-color: $faded-grey;
  border: transparent 3px solid;
  border-radius: $search-border-radius;

  .search-input {
    width: 80%;
    height: 90%;
    border: none;
    color: $dark-grey;
    outline: none;
    font-size: 18px;
    letter-spacing: 0.75px;
    background-color: transparent;
    margin-left: 10px;

    &::placeholder {
      color: $grey;
    }
  }

  .clear-search-icon {
    display: grid;
    place-items: center;

    &:hover {
      cursor: pointer;
      background-color: $light-grey;
      border-radius: $large-border-radius;
    }
  }

  &:focus-within {
    border: $green 3px solid;
    background-color: $white;
    box-shadow: $small-box-shadow;

    .search-input {
      &::placeholder {
        color: $grey;
      }
    }

    .clear-search-icon {
      display: grid;
      place-items: center;

      &:hover {
        cursor: pointer;
        background-color: $light-grey;
        border-radius: $large-border-radius;
      }
    }
  }
}

@media (max-width: 800px) {
  * {
    transition: $transition;
  }

  .search {
    width: calc($inner-tab-width-smaller - 100px);
    height: 40px;

    .search-input {
      font-size: 10px;
    }
  }
}
