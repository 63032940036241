@import '../../../../../styles.scss';

.release-notes-button {
  width: 130px;
  display: flex;
  align-items: center;
  margin-left: auto;

  &:hover {
    cursor: pointer;
  }
}

.release-notes {
  width: 85%;
  padding-top: 30px;

  .release-notes-list {
    height: 450px;
    overflow-y: auto;
    overflow-x: hidden;

    .release-note {
      width: 50%;
      margin: 20px auto;
      padding: 0 20px;
      border: solid 2px $light-grey;
      border-radius: $small-border-radius;
      overflow-y: hidden;

      .release-note-row {
        width: 100%;
        display: flex;
        align-items: center;
      }

      &:hover {
        cursor: pointer;
        border: solid 2px $green;
      }
    }

    .release-note-open {
      width: 85%;
      max-height: 420px;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: 10px auto 20px auto;
      border: solid 3px $green;
      border-radius: $small-border-radius;

      .release-note-row-header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
      }

      .release-note-row,
      .release-note-row-scroll {
        max-height: 150px;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        margin: 0 0 20px 0;
        padding: 10px 0;
        overflow-y: hidden;
      }

      .release-note-row-scroll {
        overflow-y: auto;
      }

      .close-release-info-button {
        margin: 20px 0;
      }
    }
  }
}

@media (max-width: 1200px) {
  * {
    transition: $transition;
  }

  .release-notes {
    .release-notes-list {
      .release-note-open {
        .release-note-row,
        .release-note-row-scroll {
          p {
            font-size: 12px !important;
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  * {
    transition: $transition;
  }

  .release-notes-button {
    width: 50px;
    display: flex;
    align-items: center;
    margin-left: auto;

    p {
      display: none;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
