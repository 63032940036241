@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@300;400;700&display=swap');

/* Fonts */
$main-font: 'Raleway', sans-serif;
$secondary-font: 'Press Start 2P', Georgia;

/* Widths */
$view-width: 1000px;
$inner-view-width: 800px;
$inner-tab-width: 680px;

$view-width-small: 800px;
$inner-view-width-small: 600px;
$inner-tab-width-small: 510px;

$view-width-smaller: 600px;
$inner-view-width-smaller: 400px;
$inner-tab-width-smaller: 320px;

// Heights
$card-height: 87.5vh;

/* Colors */
$grey: #999;
$white: #fff;
$red: #eb144c;
$blue: #1976d2;
$green: #26e07f;
$dark-grey: #222;
$orange: #ff9800;
$yellow: #ffc107;
$magenta: #e91e63;
$light-grey: #e5e5e5;

$code-tag: #1976d2;
$code-text: #e5e5e5;
$code-selector: #ff9900;
$code-property: #64b5f6;
$code-attribute: #64b5f6;
$code-property-value: #ea8c00;
$code-attribute-value: #ea8c00;

$faded-grey: rgba(227, 227, 227, 0.5);
$faded-green: rgba(38, 224, 127, 0.75);
$faded-dark-grey: rgba(51, 51, 51, 0.5);

/* Shadows */
$large-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1),
  0 10px 10px rgba(0, 0, 0, 0.1);
$small-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1),
  0 5px 5px rgba(0, 0, 0, 0.1);

/* Border Radius */
$small-border-radius: 5px;
$large-border-radius: 10px;
$search-border-radius: 30px;

/* Transitions */
$transition: all ease 0.25s;
