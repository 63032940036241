.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.flex-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
