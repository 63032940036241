@import 'styles.scss';

.notification-modal,
.notification-modal-error,
.notification-modal-success,
.notification-modal-warning {
  top: 0;
  left: 0;
  z-index: 10;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  font-family: $main-font;
}

.notification-modal-success {
  color: $white;
  background-color: $green;
}
