@import '../../styles.scss';

.button,
.button-secondary {
  min-width: 150px;
  height: 50px;
  font-size: 16px;
  font-weight: bold;
  padding: 0 20px;
  border: solid 3px $dark-grey;

  &:hover {
    cursor: pointer;
    color: $white;
    background-color: $green;
    border: solid 1px $green;
  }
}

.button {
  color: $dark-grey;
  background-color: transparent;
}

.button-secondary {
  color: $white;
  background-color: $dark-grey;
}

@media (max-width: 1000px) {
  * {
    transition: $transition;
  }

  .button,
  .button-secondary {
    min-width: 120px;
    height: 40px;
  }
}
