@import '../../styles.scss';

@keyframes fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.not-found-view {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 1000px;
  margin-top: 50px;
  padding-bottom: 50px;
  background-color: $white;
  box-shadow: $large-box-shadow;
  border-radius: $large-border-radius;

  h1 {
    opacity: 1;
    color: $green;
    font-size: 60px;
    animation: fade 3s linear infinite;
  }

  h2,
  h3 {
    width: 75%;
    font-size: 30px;
    color: $dark-grey;
    text-align: center;
  }

  h3 {
    font-size: 20px;
  }

  .not-found-button-container {
    display: flex;
    align-items: center;
    margin: 20px 0;

    h3 {
      margin-right: 20px;
    }
  }
}
