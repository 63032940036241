@import '../../../../../styles.scss';

.search-results-container {
  width: 100%;
  max-height: 380px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  transform: translateY(-20px);

  .displayed-search-term-row {
    display: flex;
    align-items: center;
    flex-direction: column;
    transform: translateY(-20px);
  }

  .search-results {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    transform: translateY(-20px);

    .search-result {
      width: 80%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-start;
      border: solid 2px $light-grey;
      border-radius: $small-border-radius;
      padding: 10px 20px;
      margin-top: 20px;

      &:hover {
        cursor: pointer;
        border: solid 2px $green;
        border-radius: $small-border-radius;
      }
    }
  }

  .results-counter {
    width: 25px;
    height: 25px;
    display: grid;
    place-items: center;
    color: $white;
    font-family: sans-serif;
    font-size: 12px;
    margin: 0 0 0 20px;
    border-radius: 50%;
    background-color: $grey;
  }

  &:-webkit-scrollbar {
    height: 100%;
  }
}

@media (max-width: 800px) {
  .search-results-container {
    .search-results {
      .search-result {
        p {
          font-size: 12px !important;
        }
      }
    }

    .results-counter {
      width: 30px;
      height: 30px;
      display: grid;
      place-items: center;
      color: $white;
      font-family: sans-serif;
      margin-left: 20px;
      border-radius: 50%;
      background-color: $grey;
    }
  }
}
