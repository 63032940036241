@import '../../styles.scss';

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  border-radius: 50%;
  margin: 0 auto;
  border: 5px solid $white;
  border-top: 5px solid $green;
  animation: spin 1s linear infinite;
}
