@import '../../styles.scss';

.modal-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $faded-dark-grey;
  display: flex;
  align-items: center;
  flex-direction: column;

  .modal-content {
    width: $view-width;
    max-height: 90%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    padding-bottom: 20px;
    background-color: $white;
    box-shadow: $large-box-shadow;
    border-radius: $small-border-radius;
    transform: translateX(8px);

    .modal-header {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-bottom: 20px;

      .modal-close {
        display: grid;
        place-items: center;
        margin: 10px 10px 0 auto;

        &:hover {
          cursor: pointer;
          background-color: $light-grey;
          border-radius: $large-border-radius;
        }
      }

      .modal-header-text {
        color: $dark-grey;
        margin: 0;
        padding: 0 10px;
        text-align: center;
      }
    }
  }
}

@media (max-width: 1000px) {
  .modal-container {
    .modal-content {
      width: $view-width-small;
      min-height: 40%;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: auto;
      padding-bottom: 20px;
      background-color: $white;
      box-shadow: $large-box-shadow;
      border-radius: $small-border-radius;
      transform: translateX(8px);
      overflow-y: auto;
    }
  }
}

@media (max-width: 800px) {
  .modal-container {
    .modal-content {
      width: 80vw;
      min-height: 40%;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: auto;
      padding-bottom: 20px;
      background-color: $white;
      box-shadow: $large-box-shadow;
      border-radius: $small-border-radius;
      transform: translateX(8px);
      overflow-y: auto;
    }
  }
}
