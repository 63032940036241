@import '../../styles.scss';

.big-section {
  height: 300px;
  width: $view-width;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: $faded-dark-grey;
  border-radius: $small-border-radius;
  margin-top: 20px;

  h1 {
    line-height: 2;
    font-size: 50px;
    letter-spacing: 5px;
    font-family: sans-serif;
  }

  .zero {
    color: $dark-grey;
    font-size: 30px;
  }

  .hero {
    color: $green;
    font-size: 75px;
  }
}

.small-section {
  height: 100px;
  width: $view-width;
  display: grid;
  place-items: center;
  background-color: $faded-green;
  border-radius: $small-border-radius;
  margin-top: 20px;

  p {
    text-align: center;
    line-height: 2;
    letter-spacing: 10px;
    font-family: $secondary-font;
  }
}

.landing-page-buttons {
  width: $view-width;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

@media (max-width: 1200px) {
  .big-section {
    width: $view-width-small;
    transition: $transition;

    h1 {
      font-size: 18px;
    }

    .zero {
      font-size: 24px;
    }

    .hero {
      font-size: 50px;
    }
  }

  .small-section {
    width: $view-width-small;
    transition: $transition;

    p {
      font-size: 10px;
    }
  }

  .landing-page-buttons {
    width: $view-width-small;
    transition: $transition;
  }
}

@media (max-width: 1000px) {
  .big-section {
    width: $view-width-smaller;
    transition: $transition;
  }

  .small-section {
    width: $view-width-smaller;
    transition: $transition;
  }

  .landing-page-buttons {
    width: $view-width-smaller;
    transition: $transition;
  }
}

@media (max-width: 800px) {
  .big-section {
    width: 80vw;
    transition: $transition;

    h1 {
      font-size: 14px;
    }

    .zero {
      font-size: 20px;
    }

    .hero {
      font-size: 35px;
    }
  }

  .small-section {
    width: 80vw;
    transition: $transition;

    p {
      font-size: 8px;
    }
  }

  .landing-page-buttons {
    width: 80vw;
    transition: $transition;
  }
}
