@import '../../../styles.scss';

.tab-view {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .inner-tab-content {
    height: 690px !important;
    width: $inner-view-width;
    overflow-y: auto;
    padding-bottom: 50px;
  }
}

@media (max-width: 1400px) {
  * {
    transition: $transition;
  }

  .tab-view {
    h1 {
      font-size: 24px !important;
    }

    p {
      font-size: 14px !important;
    }
  }
}
