@import '../../../../../styles.scss';

.tag-modal {
  width: 90%;
  z-index: 3000;

  .modal-attribute-table {
    width: 100%;
    min-height: 50px;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .modal-attribute-table-row {
      display: flex;
      align-items: center;
      border: solid 1px transparent;
      border-radius: $small-border-radius;
      padding: 10px 0;
      margin-bottom: 10px;

      &:hover {
        cursor: pointer;
        border: solid 1px $green;
      }
    }
  }
}

@media (max-width: 1400px) {
  .tag-modal {
    .modal-attribute-table {
      .modal-attribute-table-row {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
      }
    }
  }
}
