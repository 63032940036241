@import './styles.scss';

html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: $main-font;
  background-color: $white;
}

body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-size: cover;
  background-position: center; /* Center the background image */
  background-repeat: no-repeat; /* Prevent tiling */
  background-image: url('./assets/main-background.avif');
}
