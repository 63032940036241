@import '../../styles.scss';

@keyframes fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.icon-container-with-effect {
  opacity: 1;
  animation: fade 3s linear infinite;
}

.contact-us-text {
  width: 85%;
  margin: 20px 0;
  font-size: 16px;
  text-align: center;
  line-height: 1.5;
  letter-spacing: 1px;

  .contact-us-text-accent {
    color: $green;
    font-size: 18px;
    font-weight: bold;
  }
}

.about-container {
  width: 80%;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding-bottom: 20px;
}
