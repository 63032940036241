.attribute-modal {
  width: 80%;
  padding-bottom: 20px;

  .tag-button-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .tag-button {
      margin-bottom: 10px;
    }
  }
}
