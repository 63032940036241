@import '../../styles.scss';

.back-arrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 10px 0 auto;

  p {
    margin-left: 5px;
    color: $white;
  }

  &:hover {
    cursor: pointer;
  }
}

@media (max-width: 800px) {
  * {
    transition: $transition;
  }

  .back-arrow {
    p {
      display: none;
    }
  }
}
